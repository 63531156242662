import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Toaster from '@meforma/vue-toaster'
import { createI18n } from 'vue-i18n'
import './registerServiceWorker'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import * as components from 'vuetify/lib/components'
import * as directives from 'vuetify/lib/directives'

import "bootstrap/dist/js/bootstrap.js"
import 'bootstrap/dist/css/bootstrap.css'
import './assets/scss/main.scss';


const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    }
  }
})


const i18n = createI18n({
  locale: 'en',
  messages: {}
})


createApp(App)
.use(i18n)
.use(router)
.use(store)
.use(VueAxios, axios)
.use(Toaster)
.use(vuetify)
.mixin({
    methods: {
      formatDate: function (rawDate, timeOption) {
        if(rawDate){
          const dateNo = new Date(rawDate)
          if(timeOption == "true"){
            var minutes = 0;
            if(dateNo.getMinutes() < 10){
              minutes = "0"+dateNo.getMinutes()
            } else {
              minutes = dateNo.getMinutes()
            }
            var hours = 0;
            if(dateNo.getHours() < 10){
              hours = "0"+dateNo.getHours()
            } else {
              hours = dateNo.getHours()
            }
            return dateNo.getDate()+"/"+(dateNo.getMonth()+1)+"/"+dateNo.getFullYear()+" - "+hours+":"+minutes
          } else {
            return dateNo.getDate()+"/"+(dateNo.getMonth()+1)+"/"+dateNo.getFullYear();
          }
        } else {
          return ""
        }
      },
      formatMoney: function (value) {
        if(value > 0) {
          return new Intl.NumberFormat('en-US', { style: 'currency',currency: 'KES',currencyDisplay: 'narrowSymbol' }).format(value);
        } else {
          return "0.00";
        }
      },
      formatQty: function (value) {
        if(value > 0) {
          return new Intl.NumberFormat().format(value);
        } else {
          return "0";
        }
      }
    },
  })
.mount('#app')