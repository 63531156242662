<template>
  <header id="header">
    <div class="container">
      <div class="row" v-if="loginHeader === true">
        <div class="col-md-6 align-self-center">
          <a href="#" class="logo">
            <img src="../assets/images/camusat.png" alt="">
          </a>
        </div>
        <div class="col-md-6 align-self-center">
          <div class="select-languages">
           <div class="form">
             <select>
               <option value="English">English</option>
               <option value="French">French</option>
             </select>
           </div>
            <div class="img-wrapper">
              <i class="fa-regular fa-circle-question" style="padding-top: 5px;"></i>
              <label style="padding:0px 10px; color: #000; font-size: 16px; font-weight: 400;">Help</label>
            </div>
          </div>
        </div>
      </div>

      <div class="row main-header" v-if="mainHeader === true">
        <div class="col-md-6 align-self-end">
          <RouterLink to="home" class="logo">
            <img src="../assets/images/login-logo.png" alt="">
          </RouterLink>
          <ul class="menu">
            <li><RouterLink to="home">Home</RouterLink></li>
            <li><RouterLink to="transaction">Transactions</RouterLink></li>
            <li><RouterLink to="inventory">Inventory</RouterLink></li>
            <li><RouterLink to="#">Warehouse</RouterLink></li>
            <li><RouterLink to="#">Purchases</RouterLink></li>
            <li><RouterLink to="#">Suppliers</RouterLink></li>
            <li><RouterLink to="#">Orders</RouterLink></li>
            <li><RouterLink to="#">Reports</RouterLink></li>
          </ul>
        </div>
        <div class="col-md-3 align-self-end">
          <div class="search-form">
            <input type="text" placeholder="Search...">
            <input type="submit" id="HeaderSearch">
            <label for="HeaderSearch">
              <i class="fas fa-search"></i>
            </label>
          </div>
        </div>
        <div class="col-md-3 align-self-end">
          <ul class="info">
            <li>
              <div class="form">
                <select>
                  <option value="Jcob Mue">Jcob Mue</option>
                  <option value="Jhon Doe">Jhon Doe</option>
                  <option value="Charlie">Charlie</option>
                </select>
              </div>
            </li>
            <li>
              <span class="counter">
                12
              </span>
            </li>
            <li>
              <div class="bell-icon">
                <img src="../assets/images/icons/bell-icon.svg" alt="">
              </div>
            </li>
            <li>
              <div class="question-mark">
                <i class="fa-solid fa-circle-question"></i>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'FooterComponent',
  props: ['loginHeader', 'mainHeader']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
