import { createStore } from 'vuex'
import integrationModule from './modules/IntegrationModule';

const base_url = process.env.VUE_APP_URL

// Get token from local storage if access_token is not undefined

const headers = { 'Authorization': 'Bearer '+ JSON.parse(localStorage.getItem('access_token'))};

export default createStore({
  state: {
    platforms: [],
    countries: [],
    positions: [],
    teamtypes: [],
    teams: [],
    roles: [],
    role: {},
    users: [],
    billsOfMaterial: [],
    camusatClients: [],
    openedDropdowns : [],
    featureTypes: [],
    templates: [],
    clients: [],
    mainProjects: [],
    projects: [],
    project: {},
    projectWorkflow: [],
    projectBom: [],
    projectMaterials: [],
    workflowDeliverables: [],
    savedWorkOrders: [],
    workOrderChannel: [],
    dispatchProjects: [],
    materialChangeLogs: [],
    projectsByMainProject: [],
    platformsForUser: [],
    workOrderTypes: [
      { id: 1, name: 'Email'},
      { id: 2, name: 'Excel'},
      { id: 3, name: 'Manual'},
    ],
    featureOptions: [
      { id: 1, name: 'Mandatory'},
      { id: 2, name: 'Optional'},
    ],
    featureKPIs: [
      { id: 1, name: 'Yes'},
      { id: 2, name: 'No'},
    ],
    projectTypes: [],
    projectsWithTypes: [],
    audits: [],
    features: [],
    templatesByProject: [],
    featuresByTemplate: [],
    camusatCountries: [],
    camusatCountriesAll: [],
    dispatchMaterials: [],
    cancelTicketReasons: [],
    rejectTicketReasons: [],
    stopTeamReasons: [],
    user:{},
    ticketProblems:[],
    projectPlatforms: [],
  },
  mutations: { //synchrous
    setPlatforms (state, payload){
      // console.log('platf payload', payload)
      state.platforms = payload
    },
    setCountries (state, payload){
      state.countries = payload
    },
    setCamusatCountries (state, payload){
      state.camusatCountries = payload
    },
    setCamusatClients (state, payload){
      state.camusatClients = payload
    },
    setPositions (state, payload){
      state.positions = payload
    },
    setTeamtypes (state, payload){
      state.teamtypes = payload
    },
    setTeams (state, payload){
      state.teams = payload
    },
    setRoles (state, payload){
      state.roles = payload
    },
    setRole (state, payload){
      state.role = payload
    },
    setUsers (state, payload){
      state.users = payload
    },
    setProjects (state, payload){
      state.projects = payload
    },
    setAudits (state, payload){
      state.audits = payload
    },
    setUser (state, payload){
      state.user = payload
    },
    setFeatures (state, payload){
      state.features = payload
    },
    setMainProjects (state, payload){
      state.mainProjects = payload
    },
    setProjectsWithTypes (state, payload){
      state.projectsWithTypes = payload
    },
    setProjectsByMainProject (state, payload){
      state.projectsByMainProject = payload
    },
    setProjectWorkflow (state, payload){
      state.projectWorkflow = payload
    },
    setTemplates (state, payload){
      state.templates = payload
    },
    setProject (state, payload){
      state.project = payload
    },
    setFeatureTypes (state, payload){
      state.featureTypes = payload
    },
    setFeaturesByTemplate (state, payload){
      state.featuresByTemplate = payload
    },
    setBillsOfMaterial (state, payload){
      state.billsOfMaterial = payload
    },
    setWorkOrderTypes (state, workOrderTypes) {
      state.workOrderTypes = workOrderTypes;
    },
    setSavedWorkOrders (state, savedWorkOrders) {
      state.savedWorkOrders = savedWorkOrders;
    },
    setWorkflowDeliverables (state, workflowDeliverables) {
      state.workflowDeliverables = workflowDeliverables;
    },
    setDispatchMaterials (state, dispatchMaterials) {
      state.dispatchMaterials = dispatchMaterials;
    },
    setDispatchProjects (state, dispatchProjects) {
      state.dispatchProjects = dispatchProjects;
    },
    setMaterialChangeLogs (state, materialChangeLogs) {
      state.materialChangeLogs = materialChangeLogs;
    },
    setWorkOrderChannel (state, workOrderChannel) {
      state.workOrderChannel = workOrderChannel;
    },
    setCamusatCountriesAll (state, camusatCountriesAll) {
      state.camusatCountriesAll = camusatCountriesAll;
    },
    setProjectMaterials (state, projectMaterials) {
      state.projectMaterials = projectMaterials;
    },
    setPlatformsForUser (state, platformsForUser) {
      state.platformsForUser = platformsForUser;
    },
    setProjectPlatforms (state, projectPlatforms) {
      state.projectPlatforms = projectPlatforms;
    },
    addSavedWorkOrder (state, workOrder) {
      state.savedWorkOrders.push(workOrder);
    },
    deleteSavedWorkOrder (state, id) {
      state.savedWorkOrders.splice(state.savedWorkOrders.findIndex(workOrder => workOrder.id === id), 1);
    },
    setCancelTicketReasons (state, payload){
      state.cancelTicketReasons = payload
    },
    setRejectTicketReasons (state, payload){
      state.rejectTicketReasons = payload
    },
    setStopTeamReasons (state, payload){
      state.stopTeamReasons = payload
    },
    setTicketProblems (state, payload){
      state.ticketProblems = payload
    },
    setProjectBom (state, payload){
      state.projectBom = payload
    },
    // Mock delete Main project Mutation
    deleteMainProject(state, id){
      state.mainProjects = state.mainProjects.filter(project => project.id !== id);
    },
    //Mock create Main project Mutation
    createMainProject (state, newProject){
      state.mainProjects.push(newProject)
    },
    // Mock create project Mutation
    createProject (state, newProject){
      state.projects.push(newProject)
    },
    // Mock delete project Mutation
    deleteProject(state, id){
      state.projects = state.projects.filter(project => project.id !== id);
    },
    // Mock create projectType Mutation
    createProjectType(state, newProjectType){
      state.projectTypes.push(newProjectType);
    },
    // Mock edit projectType Mutation
    editProjectType(state, id, newProjectType){
      const project = state.projects.find(project => project.id === id);
      project.projectType = newProjectType;
    },
    // Mock delete projectType Mutation
    deleteProjectType(state, id){
      state.projectTypes = state.projectTypes.filter(projectType => projectType.id !== id);
    },
    setCurrentSubsidiary (state, subsidiary){
      state.currentSubsidiary = subsidiary
    },
    setCurrentClient (state, client){
      state.currentClient = client
    },
    setProjectType (state, projectType){
      state.projectTypes = projectType
    },
    setTemplatesByProject (state, templates){
      state.templatesByProject = templates
    }
    // Toggle headersidebar dropdown
    // toggleDropdown(state, index) {
    //   if (state.openedDropdowns.includes(index)) {
    //     state.openedDropdowns = state.openedDropdowns.filter(i => i !== index);
    //   } else {
    //     state.openedDropdowns.push(index);
    //   }
    // }
  },

  actions: { //asynchrous
    async fetchPlatforms (state) {
      const res = await fetch(base_url+'/auth/platforms',{ mode: 'cors', headers})
      // console.log(res)
      const data = await res.json()
      console.log('platforms', data)
      // remove 
      state.commit("setPlatforms",data)
    },
    async fetchPlatformsForUser (state) {
      const res = await fetch(base_url+'/platforms/user',{ mode: 'cors', headers})
      // console.log(res)
      const data = await res.json()
      state.commit("setPlatformsForUser",data.platforms)
    },
    async fetchCountries (state) {
      const res = await fetch(base_url+'/country',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setCountries",data)
    },
    async fetchPositions (state) {
      const res = await fetch(base_url+'/positions',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setPositions",data)
    },

    async fetchTeamtypes (state) {
      const res = await fetch(base_url+'/teams/type',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setTeamtypes",data)
    },
    async fetchTeams (state) {
      const res = await fetch(base_url+'/teams',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setTeams",data)
    },
    async fetchProjectWorkflow ({commit}, projectId) {
      const res = await fetch(base_url+'/projectworkflow/'+projectId,{ mode: 'cors', headers})
      const data = await res.json()
      // console.log(data)
      commit("setProjectWorkflow",data)
    },
    async fetchRoles (state) {
      const res = await fetch(base_url+'/roles',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setRoles",data)
    },
    async fetchFeatureTypes (state) {
      const res = await fetch(base_url+'/featuretype',{ mode: 'cors', headers})
      const data = await res.json()
      // console.log(data)
      state.commit("setFeatureTypes",data)
    },
    async fetchUsers (state) {
      const res = await fetch(base_url+'/users',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setUsers",data)
    },
    async fetchMainProjects (state) {
      const res = await fetch(base_url+'/mainproject',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setMainProjects",data)
    },

    async fetchProject (state, payload) {
      const res = await fetch(base_url+'/projects/'+payload,{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setProject",data)
    },

    async fetchProjectPlatforms (state, payload) {
      const res = await fetch(base_url+'/projects/'+payload,{ mode: 'cors', headers})
      const data = await res.json()
      // console.log('project platforms', data)
      state.commit("setProjectPlatforms",data.platforms)
    },

    async fetchProjectsByMainProject (state, payload) {
      const res = await fetch(base_url+'/projects/mainproject/'+payload,{ mode: 'cors', headers})
      const data = await res.json()
      // console.log(data)
      state.commit("setProjectsByMainProject",data)
    },
    async fetchMainProjectsByCountry (state, payload) {
      const res = await fetch(base_url+'/mainproject/country/'+payload,{ mode: 'cors', headers})
      const data = await res.json()
      // console.log(data)
      state.commit("setMainProjects",data)
    },
    async fetchProjects (state) {
      const res = await fetch(base_url+'/projects',{ mode: 'cors', headers})
      const data = await res.json()
      // console.log(data)
      state.commit("setProjects",data)
    },
    async fetchProjectsWithTypes (state) {
      const res = await fetch(base_url+'/projects/type/',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setProjectsWithTypes",data)
    },
    async fetchAudits (state) {
      const res = await fetch(base_url+'/platforms/audit',{ mode: 'cors', headers})
      const data = await res.json()
      data.forEach(audit => {
        audit.createdAt = new Date(audit.createdAt).toLocaleString()
      })
      state.commit("setAudits",data)
    },
    async fetchUser (state, payload) {
      const res = await fetch(base_url+'/users/'+payload,{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setUser",data)
    },
    async fetchFeatures (state) {
      const res = await fetch(base_url+'/feature',{ mode: 'cors', headers})
      const data = await res.json()
      // console.log(data)
      state.commit("setFeatures",data)
    },
    async fetchCamusatCountries (state) {
      const res = await fetch(base_url+'/country/camusat/all',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setCamusatCountries",data)
    },
    async fetchDispatchMaterials (state) {
      const res = await fetch(base_url+'/dispatchmaterial',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setDispatchMaterials",data)
    },
    async fetchCamusatClients (state) {
      const res = await fetch(base_url+'/client',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setCamusatClients",data)
    },
    async fetchProjectTypes (state) {
      const res = await fetch(base_url+'/project/type',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setProjectType",data)
    },

    async fetchTemplates (state) {
      const res = await fetch(base_url+'/template',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setTemplates",data)
    },
    async fetchTemplatesByProject (state, payload) {
      // console.log('payload', payload)
      const res = await fetch(base_url+'/template/'+payload,{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setTemplatesByProject",data)
    },
    async fetchFeaturesByTemplate (state, payload) {
      const res = await fetch(base_url+'/feature/template/'+payload,{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setFeaturesByTemplate",data)
    },
    async fetchCamusatClientsByCountry (state, payload) {
      const res = await fetch(base_url+'/client/'+payload,{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setCamusatClients",data)
    },
    async fetchMainProjectsByClient (state, payload) {
      const res = await fetch(base_url+'/mainproject/client/'+payload,{ mode: 'cors', headers})
      const data = await res.json()
      // console.log(data)
      state.commit("setMainProjects",data)
    },
    async fetchWorkFlowDeliverables (state) {
      const templateRes = await fetch(base_url+'/template',{ mode: 'cors', headers})
      const templateData = await templateRes.json()
      // console.log('templatedata', templateData)
      const BillofMaterialRes = await fetch(base_url+'/billofmaterial',{ mode: 'cors', headers})
      const bomData = await BillofMaterialRes.json()
      // console.log('bomdata', bomData)

      // Merge templateData and BoMData into one array
      const workflowDeliverables = [...templateData, ...bomData];
      // console.log(workflowDeliverables);
      state.commit("setWorkflowDeliverables", workflowDeliverables)
    },
    async fetchDispatchProjects (state) {
      const res = await fetch(base_url+'/dispatchproject',{ mode: 'cors', headers})
      const data = await res.json()
      // console.log('dispatch Project', data)
      state.commit("setDispatchProjects",data)
    },
    async fetchMaterialChangeLogs (state) {
      const res = await fetch(base_url+'/materialchangelog',{ mode: 'cors', headers})
      const data = await res.json()
      // console.log('materialChangeLogs', data)
      state.commit("setMaterialChangeLogs",data)
    },
    async deleteProject (state, payload) {
      const res = await fetch(base_url+'/projects/'+payload, {
        method: 'DELETE',
        headers
      })
      await res.json()
      // console.log(data)
      state.commit("deleteProject",payload)
    },
    async fetchWorkOrderChannel (state, payload) {
      const res = await fetch(base_url+'/workorder/channel/project/'+payload,{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setWorkOrderChannel",data)
    },
    saveWorkOrder({commit}, workOrder) {
      // console.log(workOrder);
      commit('addSavedWorkOrder', workOrder);
    },
    deleteWorkOrder({commit}, id) {
      commit('deleteSavedWorkOrder', id);
    },
    async fetchBillsOfMaterial (state) {
      const res = await fetch(base_url+'/billofmaterial',{ mode: 'cors', headers})
      const data = await res.json()
      // console.log('bom', data)
      state.commit("setBillsOfMaterial",data)
    },
    async fetchCancelTicketReasons (state) {
      const res = await fetch(base_url+'/problemCategorization/platformName/Dispatch/problemType/cancelTicket',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setCancelTicketReasons",data)
    },
    async fetchRejectTicketReasons (state) {
      const res = await fetch(base_url+'/problemCategorization/platformName/Dispatch/problemType/rejectTicket',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setRejectTicketReasons",data)
    },
    async fetchStopTeamReasons (state) {
      const res = await fetch(base_url+'/problemCategorization/platformName/Dispatch/problemType/stopTeam',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setStopTeamReasons",data)
    },
    async fetchTicketProblems (state) {
      const res = await fetch(base_url+'/problemCategorization/platformName/Dispatch/problemType/problemWithTicket',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setTicketProblems",data)
    },
    async fetchRole (state, payload) {
      const res = await fetch(base_url+'/roles/'+payload,{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setRole",data)
    },
    async fetchCamusatCountriesAll (state) {
      const res = await fetch(base_url+'/country/camusat/all',{ mode: 'cors', headers})
      const data = await res.json()
      console.log(data)
      state.commit("setCamusatCountriesAll",data)
    },
    async fetchBomByProject (state, payload) {
      const res = await fetch(base_url+'/projectworkflow/project/bom/'+payload,{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setProjectBom",data)
    },
    async fetchProjectMaterials (state, payload) {
      const res = await fetch(base_url+'/materialbom/'+payload,{ mode: 'cors', headers})
      // console.log('res', res)
      const data = await res.json()
      // console.log('project materials', data)
      state.commit("setProjectMaterials",data)
    } 
  },
  modules: {
    integration: integrationModule
  },
  getters: {
    getPlatforms : state => state.platforms,
    getCountries : state => state.countries,
    getPositions : state => state.positions,
    getTeamtypes : state => state.teamtypes,
    getMainProjects : state => state.mainProjects,
    getTeams : state => state.teams,
    getTemplates: state => state.templates,
    getRoles : state => state.roles,
    getRole : state => state.role,
    getUsers : state => state.users,
    getProjects : state => state.projects,
    getAudits : state => state.audits,
    getUser : state => state.user,
    getFeatures : state => state.features,
    getCamusatCountries : state => state.camusatCountries,
    getCamusatClients : state => state.camusatClients,
    getProjectTypes : state => state.projectTypes,
    getProjectsWithTypes : state => state.projectsWithTypes,
    getProjectWorkflow : state => state.projectWorkflow,
    getProject: state => state.project,
    getFeatureTypes: state => state.featureTypes,
    getFeaturesByTemplate: state => state.featuresByTemplate,
    getWorkOrderTypes: state => state.workOrderTypes,
    getSavedWorkOrders: state => state.savedWorkOrders,
    getBillsOfMaterial: state => state.billsOfMaterial,
    getWorkflowDeliverables: state => state.workflowDeliverables,
    getDispatchMaterials: state => state.dispatchMaterials,
    getDispatchProjects: state => state.dispatchProjects,
    getMaterialChangeLogs: state => state.materialChangeLogs,
    getCancelTicketReasons : state => state.cancelTicketReasons,
    getRejectTicketReasons : state => state.rejectTicketReasons,
    getStopTeamReasons : state => state.stopTeamReasons,
    getTicketProblems : state => state.ticketProblems,
    getWorkOrderChannel: state => state.workOrderChannel,
    getProjectsByMainProject: state => state.projectsByMainProject,
    getTemplatesByProject: state => state.templatesByProject,
    getCamusatCountriesAll: state => state.camusatCountriesAll,
    getProjectBom: state => state.projectBom,
    getProjectMaterials: state => state.projectMaterials,
    getPlatformsForUser: state => state.platformsForUser,
    getProjectPlatforms: state => state.projectPlatforms
  }
})
