import { createWebHistory, createRouter } from "vue-router";
import Login from "../views/auth/LoginPage";
// import Settings from "../views/settings/SettingsPage.vue";

const routes = [
    {
        path: "/",
        name: "Login",
        component: Login,
        meta:{
          title: "eFiber | Login",
          metaTags:[
            {
              name: 'description',
              content: 'The login page of eFiber Administration'
            }
          ]
        }
    },
    {
        path: '/auth/setpassword',
        name: 'SetPassword',
        component: () => import('../views/auth/SetPassword.vue'),
        meta:{
            title: "eFiber | Set Password",
            metaTags:[
            {
                name: 'description',
                content: 'The set password page of eFiber Administration'
            }
            ]
        }
    },
    {
        path: '/auth/selectplatform',
        name: 'SelectPlatform',
        component: () => import('../views/auth/SelectPlatform.vue'),
        meta:{
            title: "eFiber | Select Platform",
            metaTags:[
            {
                name: 'description',
                content: 'The select platform page of eFiber Administration'
            }
            ]
        }
    },
    {
        path: "/adduser",
        name: "AddUser",
        component: () => import('../views/users/AddUser.vue'),
        meta:{ title: "eFiber | Settings - Add User" }
    },
    {
        path: "/users",
        name: "ViewUsers",
        component: () => import('../views/users/ViewUsers.vue'),
        meta:{ title: "eFiber | Settings - Users" }
    },
    {
        path: "/users/:id",
        params:"id",
        name: "ViewUser",
        component: () => import('../views/users/ViewUser.vue'),
        meta:{ title: "eFiber | Settings - Manage User" }
    },
    {
        path: "/users/:id/edit",
        params:"id",
        name: "EditUser",
        component: () => import('../views/users/EditUser.vue'),
        meta:{ title: "eFiber | Settings - Edit User" }
    },
    {
        path: "/settings/clients",
        name: "AddClientPage",
        component: () => import('../views/settings/AddClients.vue'),
        meta:{ title: "eFiber | Settings - Clients" }
    },
    {
        path: "/projects",
        name: "ManageProjects",
        component: () => import('../views/projects/ManageProjects.vue'),
        meta:{ title: "eFiber | Projects" }
    },
    {
        path: "/mainproject/add",
        name: "AddMainProject",
        component: () => import('../views/projects/AddMainProject.vue'),
        meta:{ title: "eFiber | Add Main Project" }
    },
    {
        path: "/settings/project/types",
        name: "ManageProjectType",
        component: () => import('../views/settings/ManageProjectType.vue'),
        meta:{ title: "eFiber | Settings - Project Types" }
    },
    {
        path: "/audits",
        name: "ViewAudit",
        component: () => import('../views/audit/ViewAudits.vue'),
        meta:{ title: "eFiber | System Audit" }
    },
    {
        path: "/audits/material",
        name: "MaterialAudit",
        component: () => import('../views/audit/MaterialAudits.vue'),
        meta:{ title: "eFiber | Material Audit" }
    },
    {
        path: "/audits/workorder",
        name: "ProjectWorkOrderAudit",
        component: () => import('../views/audit/ProjectWorkOrderAudit.vue'),
        meta:{ title: "eFiber | Work Order Audit" }
    },
    {
        path: "/settings",
        name: "SettingsPage",
        component: () => import('../views/settings/SettingsPage.vue'),
        meta:{ title: "eFiber | Settings" }
    },
    {
        path: "/settings/roles/add",
        name: "AddUserRole",
        component: () => import('../views/settings/AddUserRole.vue'),
        meta:{ title: "eFiber | Settings - Add User Role" }
    },
    {
        path: "/settings/roles/:id",
        params:"id",
        name: "ManageUserRole",
        component: () => import('../views/settings/ManageUserRole.vue'),
        meta:{ title: "eFiber | Settings - Manage User Role" }
    },
    {
        path: "/projects/project/:projectId/configure",
        params:"projectId",
        name: "ConfigureProject",
        component: () => import('../views/projects/ConfigureProject.vue'),
        meta:{ title: "eFiber | Configure Project"} 
    },
    {
        path: "/configurations/managetemplates",
        name: "Manage Templates",
        component: () => import('../views/templates/ManageTemplates.vue'),
        meta:{ title: "eFiber | Manage Templates" }
    },
    {
        path: "/configurations/material",
        name: "ConfigureMaterial",
        component: () => import('../views/material/ConfigureMaterial.vue'),
        meta:{ title: "eFiber | Configure Material" }
    },
    {
        path: "/settings/roles",
        name: "ViewRoles",
        component: () => import('../views/settings/ViewRoles.vue'),
        meta:{ title: "eFiber | Settings - View User Roles" }
    },
    {
        path: "/settings/positions",
        name: "AddPosition",
        component: () => import('../views/settings/AddPosition.vue'),
        meta:{ title: "eFiber | Settings - Position" }
    },
    {
        path: "/settings/teams",
        name: "AddTeams",
        component: () => import('../views/settings/AddTeams.vue'),
        meta:{ title: "eFiber | Settings - Teams" }
    },
    {
        path: "/settings/teamtypes",
        name: "AddTeamTypePage",
        component: () => import('../views/settings/AddTeamType.vue'),
        meta:{ title: "eFiber | Settings - Team Type" }
    },
    {
        path: "/settings/subsidiaries",
        name: "AddCamusatCountry",
        component: () => import('../views/settings/AddCamusatCountry.vue'),
        meta:{ title: "eFiber | Settings - Subsidiaries" }
    },
    {
        path: "/configurations/projects",
        name: "ManageProject",
        component: () => import('../views/projects/ManageProjects.vue'),
        meta:{ title: "eFiber | Configure Projects" }
    },
    {
        path: "/configurations/templates",
        name: "AddTemplate",
        component: () => import('../views/templates/AddTemplate.vue'),
        meta:{ title: "eFiber | Configure Templates" }
    },
    {
        path: "/settings/categorization",
        name: "TicketCategorizations",
        component: () => import('../views/settings/ProblemCategorizations.vue'),
        meta:{ title: "eFiber | Settings - Ticket Categorization" }
    },
    {
        path: "/settings/permissions",
        name: "ManagePermissions",
        component: () => import('../views/settings/ManagePermissions.vue'),
        meta:{ title: "eFiber | Settings - Permissions" } 
    },
    {
        path: "/integration/elements/add",
        name: "AddNetworkElement",
        component: () => import('../views/integration/AddNetworkElement.vue'),
        meta:{ title: "eFiber | Integration - Network Elements" }
    },
    {
        path: "/integration/configurations",
        name: "IntegrationProjectConfig",
        component: () => import('../views/integration/ProjectConfig.vue'),
        meta:{ title: "eFiber | Integration - Project Configuration" }
    },
    {
        path: "/integration/elements/manage",
        name: "ManageNetworkElement",
        component: () => import('../views/integration/ManageNetworkElement.vue'),
        meta:{ title: "eFiber | Integration - Manage Network Elements" }
    },
    {
        path: "/integration/qrcode",
        name: "QRCodeTest",
        component: () => import('../views/integration/QrCodeTest.vue'),
        meta:{ title: "eFiber | Integration - QR Code Test" }
    },
    {
        path: "/:pathmatch(.*)",
        name: "NotFound",
        component: () => import('../components/NotFound.vue'),
        meta:{ title: "eFiber | Not Found" }
    },
    {
        path: "/no-permissions",
        name: "NoPermission",
        component: () => import('../components/NoPermissions.vue'),
        meta:{ title: "eFiber | No Permission" }
    }

];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

function canAccess(user, platformId = process.env.VUE_APP_ADMIN_PLATFORM_ID) {
	console.log(platformId);
	return user?.platforms?.includes(platformId);
}

async function isAuthenticated(to) {
	if (to.name == '/projects') {
		if (this.$route.query.changeplatform) {
			localStorage.setItem(
				'access_token',
				JSON.stringify(this.$route.query.changeplatform)
			);
		}
	}
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization:
				'Bearer ' +
				JSON.parse(localStorage.getItem('access_token')),
		},
	};
	const res = await fetch(
		process.env.VUE_APP_URL + '/users/profile',
		requestOptions
	);
	const data = await res.json();
	if (data.status == true) {
		const user = data.user;
		localStorage.setItem(
			'user_data',
			JSON.stringify(data.user)
		);
		return user;
	} else {
		return null;
	}
}

router.beforeEach(async (to, from, next) => {
    const user = await isAuthenticated(to);
	if (to.name === 'Login') {
		return next(user? '/projects': undefined);
	} else if (to.name === 'NoPermission') {
		return canAccess(user)? next('/'): next();
	}
	else if (!user) {
		return next('/');
    } else if (!canAccess(user)) {
			if(to.name !== 'NoPermissions') {
				return next('/no-permissions');
			}
			else {
				return next();
			}
	}

    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eslint-disable-next-line no-unused-vars
    to.matched.slice().reverse().forEach(match => {
      if (match.meta && match.meta.title) {
        document.title = match.meta.title;
      }
  
      // Remove any existing meta tags
      Array.from(document.querySelectorAll('meta[name], meta[property]')).forEach(el => el.remove());
  
      // Add the meta tags defined in the route's meta field
      if (match.meta && match.meta.metaTags) {
        match.meta.metaTags.forEach(tagDef => {
          const tag = document.createElement('meta');
  
          Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
          });
  
          document.head.appendChild(tag);
        });
      }
    });
  
    next();
});

export default router;